<template>
    <div>
        <validation-provider
            v-slot="{ errors }"
            :name="name"
            :rules="rules"
            :vid="vid"
        >
            <label :for="vid" class="col-form-label">{{ name }}</label>
            <b-input-group>
                <template #prepend>
                    <b-button
                        variant="info"
                        @click="openWindow"
                    >
                        {{ $t('image') }}
                    </b-button>
                </template>
                <b-form-input
                    :id="vid"
                    :placeholder="placeholder"
                    :state="errors.length > 0 ? false : null"
                    :type="type"
                    :value="value"
                    dir="ltr"
                    readonly
                    @input="emitInput"
                />
            </b-input-group>
            <b-img v-show="value" :src="value" width="130" class="mt-1 img-thumbnail"/>

        </validation-provider>
    </div>
</template>

<script>
import { DefaultInputProps } from "@/layouts/components/form/defaultProps";
import { BFormInput, BImg, BInputGroup } from "bootstrap-vue";
export default {
    name: "CustomFmButton",

    components: {
        BImg,
        BInputGroup,
        BFormInput
    },

    props: {
        ...DefaultInputProps
    },

    methods: {
        emitInput(value) {
            this.$emit('input', value)
        },
        openWindow() {
            // window.open(`http://localhost:8080/file-manager/fm-button`, 'fm', 'width=1400,height=800');
            window.open(`${process.env.VUE_APP_BASE_DASHBOARD_URL}file-manager/fm-button`, 'fm', 'width=1400,height=800');
        }
    }
}
</script>
